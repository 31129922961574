.button-white {
    background-color: white;
    width: 75px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.custom-table .ant-table-thead>tr>th {
    background: transparent linear-gradient(0deg, #878787 0%, #5F5F5F 30%, #000000 100%) 0% 0% no-repeat padding-box !important;
    color: white !important;
    font-family: 'Roboto', sans-serif !important;
    height: 10px !important;
    line-height: 10px !important;
}