/* Override the default styles for leaflet-popup-content-wrapper */
.custom-popup .leaflet-popup-content-wrapper {
    background-color: #f0f8ff;
    /* Light blue background */
    border-radius: 10px;
    /* Rounded corners */
    padding: 15px;
    /* Add padding inside the wrapper */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

/* Optional: Additional styles for content inside the popup */
.custom-popup .leaflet-popup-content {
    font-size: 14px;
    color: #333;
}

/* Optional: Change the arrow tip of the popup */
.custom-popup .leaflet-popup-tip {
    border-top-color: #f0f8ff;
}

.leaflet-popup-content-wrapper {
    background-color: rgba(20, 16, 13, 0.6) !important;
    border-radius: 10px !important;
    padding: 5px !important;
    color: white;
}

.leaflet-popup-tip {
    background-color: rgba(20, 16, 13, 0.5) !important;
}